@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
* {
  margin: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  background: #fff;
  /* color: #fff; */
}
.App {
  font-family: Inter, sans-serif;
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
  max-width: 1200px;
  margin: 0 auto;
}
.serif {
  font-family: serif;
  font-weight: normal;
}
.header {
  padding: 0.5rem 1rem;
  text-align: left;
  background: #000;
  position: fixed;
  width: 100%;
  z-index: 1;
  background: #ffffffc0;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(4px);
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
  display: grid;
  grid-template-columns: max-content 1fr;
  place-items: center start;
  grid-gap: 1rem;
}
.header h1 {
  font-weight: normal;
  font-size: 1.25rem;
}

.header .logo {
  font-size: 1.5rem;
  font-weight: normal;
  width: max-content;
  aspect-ratio: 1/1;
  display: grid;
  place-items: center;
  font-family: serif;
  font-style: italic;
  background: #26f;
  /* aspect-ratio: 1/1; */
  color: white;
  padding: 0.5rem;
}
.grey {
  color: #777;
}
.spacer {
  height: 80px;
}
.hero {
  width: 100%;
  margin-bottom: 1rem;
}
.tagline {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  display: grid;
  grid-gap: 1rem;
}
.tagline h1 {
  font-size: 2.5rem;
}
.steps {
  margin-top: 1rem;
  padding: 1rem;
  display: grid;
  grid-gap: 1rem;
}
.steps div {
  text-align: start;
}
.steps div p {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: #777;
}
.images {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
}
.imageCard {
  background: #fff;
  border-radius: 12px;
  /* margin: 1rem auto; */
  padding: 1rem;
  padding-bottom: 1rem;
  box-shadow: 0 2px 6px 0 rgba(31, 38, 60, 0.17);

  margin-top: 100px;
  display: grid;
  place-items: start center;
  grid-template-rows: max-content max-content max-content;
  height: 500px;
  max-width: 400px;
  width: 100%;
  grid-gap: 1rem;
}
.imageCard img {
  height: 350px;
  max-width: 100%;
  object-fit: cover;
  object-position: top;
  position: relative;
  bottom: 100px;
  margin-bottom: -100px;
  border-bottom: 1px solid #00000030;
}
.imageCard .title {
  font-size: 2rem;
  /* font-weight: bold; */
  color: #000;
}
.imageCard p {
  color: #777;
}

.end {
  display: grid;
  place-items: center;
  grid-gap: 2rem;
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

button,
a {
  /* border-radius: 8px; */
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  color: #fff;
  cursor: pointer;
  transition: border-color 0.25s;
  text-decoration: none;
}
a:hover,
button:hover {
  border-color: #f9f9f9;
  background: #646cff;
  color: #f9f9f9;
}
a:focus,
button:focus,
a:focus-visible,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}
